<template>
  <div>
    <table-server-side-v-1-widget
      :ref="refTable"
      :url="url"
      :columns="cColumns"
      :actions="actionsTable"
      @clickView="onTableClickView"
    />
    <!-- <pre>{{ url }}</pre> -->
    <b-modal ref="refModalRefundDetails" title="ข้อมูลเพิ่มเติม" size="md" hide-footer @cancel="modalForm = {}">
      <h5 v-if="modalForm.user" class="mb-1">ชื่อผู้ขอคืนเงิน {{ modalForm.user.NAME }}</h5>
      <dl v-for="item in userRefundDetails" :key="item.key" class="row" :class="item.rowClass">
        <dt class="col-sm-5">
          {{ item.title }}
        </dt>
        <dd class="col-sm-7 text-sm-right ml-1 ml-sm-0">
          <span v-if="modalForm.user">{{ gFormatNumberToCurrency(modalForm.user[item.keyData] || 0) }} บาท</span>
        </dd>
      </dl>

      <hr class="mb-2" />

      <h5 v-if="modalForm.partner" class="mb-1">ชื่อผู้ถูกขอคืนเงิน {{ modalForm.partner.NAME }}</h5>
      <dl v-for="item in adminRefundDetails" :key="item.key" class="row" :class="item.rowClass">
        <dt class="col-sm-5">
          {{ item.title }}
        </dt>
        <dd class="col-sm-7 text-sm-right ml-1 ml-sm-0">
          <span v-if="modalForm.partner">{{ gFormatNumberToCurrency(modalForm.partner[item.keyData] || 0) }} บาท</span>
        </dd>
      </dl>
      <!-- <pre>{{ modalForm }}</pre> -->
    </b-modal>
  </div>
</template>

<script>
export default {
  props: {
    url: {
      type: String,
      default: '',
    },
    refTable: {
      type: String,
      default: '',
    },
    keyTab: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      modalForm: {},
      userRefundDetails: [
        { key: 'u1', title: 'ยอดคงเหลือ (ก่อนคืนเงิน)', keyData: 'credit_balance' },
        { key: 'u2', title: 'ยอดคืนเงิน', keyData: 'credit_amount', rowClass: 'text-danger' },
        { key: 'u3', title: 'ยอดคงเหลือ (หลังคืนเงิน)', keyData: 'credit_total' },
      ],
      adminRefundDetails: [
        { key: 'a1', title: 'ยอดคงเหลือ (ก่อนคืนเงิน)', keyData: 'credit_balance' },
        {
          key: 'a3',
          title: 'ยอดคืนเงินจากรูปที่ขายได้หลังจากหักส่วนต่าง',
          keyData: 'credit_amount',
          rowClass: 'text-danger',
        },
        { key: 'a4', title: 'ยอดคงเหลือ (หลังคืนเงิน)', keyData: 'credit_total' },
      ],
      actionsTable: [{ keyEmit: 'clickView', label: 'ดูเพิ่มเพิม', iconName: 'EyeIcon' }],
    }
  },
  computed: {
    cColumns() {
      const c = [
        {
          label: 'วันที่',
          field: 'created_at',
          formatFn: value => this.$date(value).format('YYYY-MM-DD HH:mm:ss'),
          tdClass: 'text-center',
          thClass: 'text-center',
        },
        {
          label: 'ชื่อผู้ขอคืนเงิน',
          field: 'user_name',
          tdClass: 'text-center',
          thClass: 'text-center',
        },
        {
          label: 'ยอดคงเหลือ (ก่อนคืนเงิน)',
          field: 'credit_balance',
          type: 'currency',
          tdClass: 'text-right text-success',
          thClass: 'text-right',
        },
        {
          label: 'ยอดคืนเงิน*',
          field: 'credit_amount',
          type: 'currency',
          tdClass: 'text-right text-danger',
          thClass: 'text-right',
        },
        {
          label: 'ยอดคงเหลือ (หลังคืนเงิน)',
          field: 'credit_total',
          type: 'currency',
          tdClass: 'text-right text-warning',
          thClass: 'text-right',
        },
      ]

      if (this.keyTab === 'all_refund') {
        c.splice(2, 0, {
          label: 'ผู้ที่ถูกขอคืนเงิน',
          field: 'partner_email',
          thClass: 'text-center',
          tdClass: 'text-center',
        })
      }

      return c
    },
  },
  methods: {
    async onTableClickView(data) {
      this.gOpenPageLoading()
      const resp = await this.api
        .get(`api/admin/userTransactionData/show-refund-info/${data.transaction_data_as_ref}`)
        .catch(() => null)
      // console.log('onTableClickView', resp)
      this.gClosePageLoading()
      if (!resp) {
        // alert บอก error
        return
      }
      const findIndexUser = [...resp].findIndex(v => v.text_role === 'client')

      this.modalForm = {
        partner: {
          ...[...resp][findIndexUser === 0 ? 1 : 0],
        },
        user: {
          ...[...resp][findIndexUser],
        },
      }
      this.$refs.refModalRefundDetails.show()
    },
  },
}
</script>

<style lang="scss"></style>
